import React, { Component } from "react";
import { Viewer,Entity,Cesium3DTileset,CameraFlyTo, Model } from "resium";
import { Cartesian3 } from "cesium";

import Cesium from "cesium";
//Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxZmRmYTI4Yi1lYjJhLTRkMWEtYmYwYi00OTRkYTU1MDlkOGQiLCJpZCI6MTMzMTcsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1NjMwMDE0Njh9.Nrcz8Y6H4tP2IbGciDiYbl6PzIk2YV0FVrfIZNhGZ4M";

export default class RelatimeMap extends Component {
    constructor(props) {
	super(props);
    }


    
    render() {
	var myposition = Cartesian3.fromDegrees(this.props.lon,this.props.lat,this.props.alt);


	var desc = "Lat: " + this.props.lat + "<br/>" +
	    "Lon: " + this.props.lon + "<br/>" +
	    "Alt: " + this.props.alt + "<br/>" +
	    "<br/>";

	
	var uav = <Entity
	id="id0"
	description={desc}
	name="UAV"
	point={{ pixelSize: 20 }}
	position={myposition}
	   />;
	   
       if (this.props.lon===0 && this.props.lat===0) {
	   uav = <Entity/>;
       }


	// 

	//const modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(myposition);
	

	//	<Model
	//	url="./3d/scene.gltf"
	//modelMatrix={modelMatrix}
	//minimumPixelSize={128}
	//maximumScale={20000}
	///>

	return  <div><div id="credit"/><Viewer creditContainer="credit" timeline={false} baseLayerPicker={false} fullscreenButton={false} sceneModePicker={false} selectionIndicator={false} navigationHelpButton={false} infoBox={true} homeButton={false} animation={false} geocoder="false" style={{ position: "absolute", top: "100vh-480px", left: "100vw-640px", right: 0, bottom: 0, width: 640, height: 480 }} ref={e => {this.viewer = e && e.cesiumElement;}}>

	{uav}


	</Viewer></div>;
    }
}
