import React, { Component } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";

export default class ConfirmEmail extends Component {
    constructor(props) {
	super(props);

	this.state = {
	    email: "",
	    errorMessage: "",
	    errorCode: "",
	    verificationCode: ""
	};
    }

    validateForm() {
	return this.state.verificationCode.length > 0;
    }

    handleChange = event => {
	this.setState({
	    [event.target.id]: event.target.value
	});
    }

    handleSubmit = event => {
	event.preventDefault();

	console.log(this.props);
	this.props.props.cognitoUser.confirmRegistration(this.state.verificationCode,true,function(err, result) {
	    if (err) {
		this.setState({
		    errorCode: err.code,
		    errorMessage: err.message
		});
		return;
	    }
	    console.log('call result: ' + result);
	    this.props.props.setEmailConfirmed();
	}.bind(this));
    }


    render() {
	return (
	    	<div className="ConfirmEmail">
		Please confirm your email address. We have already sent you a confirmation code.
		<form onSubmit={this.handleSubmit}>

		<FormGroup controlId="verificationCode" bssize="large">
		<FormLabel>Verification Code</FormLabel>
		<FormControl
            autoFocus
            type="text"
            value={this.state.verificationCode}
            onChange={this.handleChange}
		/>
		</FormGroup>

		<font color="red">{this.state.errorMessage}</font><br/>
		<Button
            block
            bssize="large"
            disabled={!this.validateForm()}
            type="submit"
		>
		confirm
            </Button>
		</form>

		</div>

	);
    }

}
