import React, { Component } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import AWS from 'aws-sdk';

export default class ClaimDevice extends Component {
    constructor(props) {
	super(props);

	this.state = {
	    deviceId: "",
	    pairingId: "",
	    errorMessage: ""
	}
    }

    validateForm() {
	return this.state.deviceId.length > 0 && this.state.pairingId.length > 0;
    }

    handleChange = event => {
	this.setState({
	    [event.target.id]: event.target.value,
	    errorMessage: ""
	});
    }

    handleSubmit = event => {
	event.preventDefault();

	this.setState({
	    errorMessage: ""
	});


	AWS.config.update({region:'us-west-2'});
	var lambda = new AWS.Lambda();
	var payload = {
	    "userid" : AWS.config.credentials.identityId,
	    "deviceid" : this.state.deviceId,
	    "pairingid" : this.state.pairingId
	};
	console.log(payload);
	var params = {
	    FunctionName: 'claimDevice',
	    Payload: JSON.stringify(payload)
	};
	lambda.invoke(params, function(err, data) {
	    if (err) {
		this.setState({
		    errorMessage: "Backend Error."
		});
	    }
	    else   {
		console.log(data);
		if (data.StatusCode===200 && !data.Payload.includes("errorMessage")) {
		    console.log("SUCCESS");
		    this.props.props.setClaimDeviceMode(false);
		}
		else {
		    this.setState({

			// b0b0 TODO be specific about error case (device does not exist; wrong code; backend error)
			errorMessage: "Claiming device failed."
		    });
		}
	    }
	    
	}.bind(this));

    }
    
    render() {
	return (
	    	<div className="ClaimDevice">
		<form onSubmit={this.handleSubmit}>

		<FormGroup controlId="deviceId" bssize="large">
		<FormLabel>Device ID</FormLabel>
		<FormControl
            autoFocus
            type="text"
            value={this.state.deviceId}
            onChange={this.handleChange}
		/>
		</FormGroup>

		<FormGroup controlId="pairingId" bssize="large">
		<FormLabel>Pairing ID</FormLabel>
		<FormControl
            autoFocus
            type="text"
            value={this.state.pairingId}
            onChange={this.handleChange}
		/>
		</FormGroup>

	    
		<font color="red">{this.state.errorMessage}</font><br/>
		<Button
            block
            bssize="large"
            disabled={!this.validateForm()}
            type="submit"
		>
		claim
            </Button>
		</form>

		</div>

	);
    }

}
