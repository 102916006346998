import React, { Component } from "react";
import "./ActionBar.css";

export default class ActionBar extends Component {

    constructor(props) {
	super(props);
    }


    
    render() {
	var button = <li></li>;
	if (this.props.cancel) {
	}
	else if (this.props.icedisconnected===true) {
	    button = <li><button onClick={this.props.connect}>connect</button></li>
	}
	else {
	    if (this.props.receivingvideo===true) {
		button =  <li><button onClick={this.props.disconnect}>disconnect</button></li>
	    }
	    else {
		button =  <li><button onClick={this.props.cancelConnect}>cancel</button></li>
	    }
	}

	    //<li>Map</li>
	    //<li>Fullscreen</li>
	    //<li>Config</li>

	return <div>
	    <ul>
	    {button}
	    </ul>
	</div>;
    }
}
