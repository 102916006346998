import React, { Component } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import AWS from 'aws-sdk';
import {
    CognitoUserPool,
    CognitoUser
} from 'amazon-cognito-identity-js';

export default class ForgotPassword extends Component {
    constructor(props) {
	super(props);

	this.state = {
	    errorMessage: "",
	    errorCode: "",
	    email: "",
	    verificationCode: "",
	    newPassword: "",
	    emailsent: false
	};
	this.cognitoUser = null;
    }

    validateForm() {
	return true; //b0b0 FIXME
    }
    validateForm2() {
	return this.state.verificationCode.length>0;
    }

    handleChange = event => {
	this.setState({
	    [event.target.id]: event.target.value
	});
    }

    handleSubmit = event => {
	event.preventDefault();
	this.setState({
	    errorMessage: "",
	    errorCode: ""
	});

	if (this.state.verificationCode.length>0) {
	    console.log("stage 2");

	    this.cognitoUser.confirmPassword(this.state.verificationCode, this.state.newPassword, {
		onFailure: function(err) {
		    console.log(err);
		    this.setState({
			errorMessage: err.message,
			errorCode: err.code
		    });

		}.bind(this),
		onSuccess: function() {
		    console.log("Success");
		    console.log(this.props);
		    this.props.props.setForgotPasswordState(false);
		}.bind(this)
	    });
					     
	}
	else {

	AWS.config.region = 'us-east-1';
	this.poolData = {
  	    UserPoolId : 'us-east-1_7dojC06dm', //b0b0
	    ClientId :   '10gmq555pdji7gsoc9qpl8lajn' //b0b0
	};
	this.userPool = new CognitoUserPool(this.poolData);

	var userData = {
	    Username : this.state.email,
	    Pool : this.userPool
	};

	    this.cognitoUser = new CognitoUser(userData);	
	    this.cognitoUser.forgotPassword({
            onSuccess: function (result) {
		console.log(result);
		this.setState({
		    emailsent: true
		});
            }.bind(this),
            onFailure: function(err) {
		console.log(err);
		this.setState({
		    errorMessage: err.message,
		    errorCode: err.code
		});
            }.bind(this)
	});
	}
    }


    render() {
	if (this.state.emailsent) {
	    return (
		    <div className="ForgotPasswordSecondStage">
		    <form onSubmit={this.handleSubmit}>

		    <FormGroup controlId="verificationCode" bssize="large">
		    <FormLabel>Verification Code</FormLabel>
		    <FormControl
		autoFocus
		type="text"
		value={this.state.verificationCode}
		onChange={this.handleChange}
		    />
		    </FormGroup>

		    <FormGroup controlId="newPassword" bssize="large">
		    <FormLabel>New Password</FormLabel>
		    <FormControl
		type="password"
		value={this.state.newPassword}
		onChange={this.handleChange}
		    />
		    </FormGroup>
		    
		
		    <font color="red">{this.state.errorMessage}</font><br/>
		    <Button
		block
		bssize="large"
		disabled={!this.validateForm2()}
		type="submit"
		    >
		    confirm
		</Button>
		    </form>

		</div>

	    );
	}
	else {
	return (
	    	<div className="ForgotPassword">
		<form onSubmit={this.handleSubmit}>

		<FormGroup controlId="email" bssize="large">
		<FormLabel>Email</FormLabel>
		<FormControl
            autoFocus
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
		/>
		</FormGroup>

		<font color="red">{this.state.errorMessage}</font><br/>
		<Button
            block
            bssize="large"
            disabled={!this.validateForm()}
            type="submit"
		>
		confirm
            </Button>
		</form>

		</div>

	);
	}
    }

}
