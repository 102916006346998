import React, { Component } from "react";
import "./StatusBar.css";

export default class StatusBar extends Component {

    constructor(props) {
	super(props);
	this.state =  {
	    osd_connectiontype : null,
	    osd_dataused : null,
	    osd_latency : null,
	    osd_bandwidth : null,
	    osd_framesdecoded : null,
	    osd_framesdropped : null,
	    osd_availableincomingbitrate : null
	}
	this.oldtimestamp = 0;
	this.oldbytes = 0;
	this.statsOutput = null;
	setInterval(this.processstats.bind(this),1000);
    }

    // connectionstate is a bit messed up and should be consolidated. it is passed like this:
    // mqttstate={this.props.data.connected} icedisconnected={this.state.disconnected} receivingvideo={this.state.receivingvideo} {cancel=this.state.cancelConnect}
    
        processstats() {
	if (this.props.pc) {

	    this.props.pc.getStats(null).then(stats => {
		stats.forEach(report => {
		    if (report.type == "inbound-rtp") {
			Object.keys(report).forEach(statName => {
			    if (statName == "framesDecoded") {
				this.setState({osd_framesdecoded : report[statName]});
			    }
			    if (statName == "bytesReceived") {
				this.setState({osd_dataused : parseInt(report[statName]/1024/1024,10)});

				var bytes = parseInt(report[statName]);
				var timestamp = report["timestamp"];
				if (this.oldtimestamp!=0) {
				    var bandwidth = Math.floor((bytes-this.oldbytes)/(timestamp-this.oldtimestamp)*8);
				    if (bandwidth<0) bandwidth = 0;
				    this.setState({osd_bandwidth : bandwidth});
				}
				this.oldtimestamp = timestamp;
				this.oldbytes = bytes;

			    }
			});
		    }
		    else if (report.type == "track") {
			Object.keys(report).forEach(statName => {
			    if (statName == "framesDropped") {
				this.setState({osd_framesdropped : report[statName]});
			    }
			});
		    }
		    else if (report.type == "candidate-pair") {
			Object.keys(report).forEach(statName => {
			    if (report["nominated"]) {

				if (statName == "currentRoundTripTime") {
				    this.setState({osd_latency : parseInt(report[statName]*1000/2)});
				    
				}
				if (statName == "availableIncomingBitrate") {
				    this.setState({osd_availableincomingbitrate : parseInt(report[statName]/1024,10)});
				}

				stats.forEach(search => {
				    if (search.type == "local-candidate" && search["id"]==report["localCandidateId"]) {
					this.setState({localCandidateType : search["candidateType"]});
					// 'ip' should be 'address' according to WebRTC specs, but as of 2019-07-23 chrome only supports 'ip'
					// also 'protocol' should be 'relayProtocol'
					this.setState({localCandidateInfo : search["candidateType"] + " " + search["ip"] + " (" + search["protocol"]+")"});
				    }
				    if (search.type == "remote-candidate" && search["id"]==report["remoteCandidateId"]) {
					this.setState({remoteCandidateType : search["candidateType"]});
					this.setState({remoteCandidateInfo : search["candidateType"] + " " + search["ip"] + " (" + search["protocol"]+")"});
				    }
				});
			    }
			});
		    }
		});
	    });
	}
    }

    
    render() {
	var accessTechString = "";
	if (this.props.at == 0) {
	    accessTechString = "n/a";
	}
	else if (this.props.at == 1) {
	    accessTechString = "POTS";
	}
	else if (this.props.at == 2) {
	    accessTechString = "GSM";
	}
	else if (this.props.at == 4) {
	    accessTechString = "GSM_COMPACT";
	}
	else if (this.props.at == 8) {
	    accessTechString = "GPRS";
	}
	else if (this.props.at == 16) {
	    accessTechString = "EDGE";
	}
	else if (this.props.at == 32) {
	    accessTechString = "UMTS";
	}
	else if (this.props.at == 64) {
	    accessTechString = "HSDPA";
	}
	else if (this.props.at == 128) {
	    accessTechString = "HSUPA";
	}
	else if (this.props.at == 256) {
	    accessTechString = "HSPA";
	}
	else if (this.props.at == 512) {
	    accessTechString = "HSPA_PLUS";
	}
	else if (this.props.at == 1024) {
	    accessTechString = "HSPA_1XRTT";
	}
	else if (this.props.at == 2048) {
	    accessTechString = "EVDO0";
	}
	else if (this.props.at == 4096) {
	    accessTechString = "EVDOA";
	}
	else if (this.props.at == 8192) {
	    accessTechString = "EVDOB";
	}
	else if (this.props.at == 16384) {
	    accessTechString = "LTE";
	}
	else {
	    accessTechString = "ERROR";
	}


	// mqttstate={this.props.data.connected} icedisconnected={this.state.disconnected} receivingvideo={this.state.receivingvideo} {cancel=this.state.cancelConnect}
	var display_connectionstate = "";
//	if (!this.props.mqttstate) {
//	    display_connectionstate = "ERROR"; // no internet connection?	}
//	else  {
	    if (this.props.cancel===true) {
		display_connectionstate = "canceling";
	    }
	    else {
		if (this.props.icedisconnected===true) {
		    display_connectionstate = "disconnected";
		}
		else {
		    display_connectionstate = "connecting";
		    if (this.props.receivingvideo===true) {
			display_connectionstate = "connected";
		    }
		}
	    }
//	}


	var display_connectiontype = "";
	if ((this.state.localCandidateType==="srflx" || this.state.localCandidateType==="prflx" || this.state.localCandidateType==="host") && (this.state.remoteCandidateType==="srflx" || this.state.remoteCandidateType==="prflx" || this.state.remoteCandidateType==="host") && this.props.receivingvideo===true) {
	    display_connectiontype  = "[P2P]";
	}
	else if ((this.state.localCandidateType==="relay" || this.state.remoteCandidateType==="relay") && this.props.receivingvideo===true) {
	    display_connectiontype  = "[RELAY]";
	}

	var display_bat = "Bat: " + this.props.bat+"%";
	if (this.props.bat===-1 || this.props.icedisconnected===true || this.props.receivingvideo===false) {
	    display_bat = "Bat: " + "n/a";
	}
	var display_signal = this.props.signal+"%";
	if (this.props.signal===0) {
	    display_signal = "n/a";
	}

	// TODO only show this if debug is enable
	var connectiontype_tooltip = this.state.remoteCandidateInfo + " <-> " + this.state.localCandidateInfo;

	var display_latency = this.state.osd_latency+"ms";
	if (this.state.osd_latency===null || this.props.icedisconnected===true || this.props.receivingvideo===false) {
	    display_latency = "n/a";
	    //this.setState({osd_latency : null });
	}

	var display_bandwidth = this.state.osd_bandwidth + " kbit/s";
	if (this.state.osd_bandwidth===null || this.props.icedisconnected===true || this.props.receivingvideo===false) {
	    display_bandwidth = "n/a";
	    //	    this.setState({osd_bandwidth : null });
	}

	var display_dataused = this.state.osd_dataused + " MB";
	if (this.state.osd_dataused===null || this.props.icedisconnected===true || this.props.receivingvideo===false) {
	    display_dataused = "n/a";
	    //this.setState({osd_dataused : null });
	}

	var display_alt = "Alt: " + this.props.alt + "m";
	if (this.props.alt===0 || this.props.icedisconnected===true || this.props.receivingvideo===false) {
	    display_alt = "Alt: n/a";
	}

	var mavlinksection;
	if (this.props.firmware.flavor==='main'||this.props.firmware.flavor==='link') {
	    mavlinksection = <br/>;
	}
	else {
 	    mavlinksection = 	    <div><li><div title="Relative Altitude">{display_alt}</div></li>
		<li style={{width: 15+'%'}}><div title="Distance from GCS">Dist: {this.props.dist}</div></li>
		<li><div title="UAV Battery">{display_bat}</div></li></div>;
	}
	
	
	return <div>
	    <ul>
	    <li><div title="Cellular Technology">{accessTechString}</div></li>
	    <li><div title="Signal Strength">{display_signal}</div></li>
	    <li style={{width: 15+'%'}}><div title={connectiontype_tooltip} >{display_connectionstate} {display_connectiontype}</div></li>
	    <li><div title="current network latency">{display_latency}</div></li>
	    <li><div title="current video bitrate">{display_bandwidth}</div></li>
	    <li><div title="Data used in session">{display_dataused}</div></li>
	    {mavlinksection}
	    </ul>
	</div>;
    }
}
