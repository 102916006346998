import React, { Component } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import "./SignUp.css";
import ConfirmEmail from "./ConfirmEmail.js";
import AWS from 'aws-sdk';
import {
    CognitoUserPool,
    CognitoUserAttribute
} from 'amazon-cognito-identity-js';


export default class SignUp extends Component {
    constructor(props) {
	super(props);

	this.state = {
	    errorMessage: "",
	    signedUp: false,
	    email: "",
	    password: "",
	    firstname: "",
	    lastname: ""
	};
	this.cognitoUser = null;
    }


    validateForm() {
	// b0b0: TODO do direct verification of password constraints and show user-readable errormessage
	return this.state.email.length > 0 && this.state.firstname.length > 0 && this.state.lastname.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
	this.setState({
	    [event.target.id]: event.target.value
	});

    }

    handleSubmit = event => {
	event.preventDefault();
	console.log("do shit here");

	AWS.config.region = 'us-east-1';
	this.poolData = {
  	    UserPoolId : 'us-east-1_7dojC06dm',
	    ClientId :   '10gmq555pdji7gsoc9qpl8lajn'
	};
	this.userPool = new CognitoUserPool(this.poolData);


	var givenName = {
	    Name : 'given_name',
	    Value : this.state.firstname
	};

	var familyName = {
	    Name : 'family_name',
	    Value : this.state.lastname
	};
	
	var dataEmail = {
	    Name : 'email',
	    Value : this.state.email
	};

	var attributeGivenName = new CognitoUserAttribute(givenName);
	var attributeFamilyName = new CognitoUserAttribute(familyName);
	var attributeEmail = new CognitoUserAttribute(dataEmail);

	var attributeList = [];
	attributeList.push(attributeGivenName);
	attributeList.push(attributeFamilyName);
	attributeList.push(attributeEmail);
	console.log(attributeList);

	this.userPool.signUp(this.state.email, this.state.password, attributeList, null, function(err, result){
	    if (err) {
		console.log(err);
		this.setState({
		    errorMessage: err.message
		});

		return;
	    }
	    this.cognitoUser = result.user;
	    console.log('user name is ' + this.cognitoUser.getUsername());
	    //alert("Signup successful.");
	    //location.reload();
	    this.setState({signedUp: true});
	}.bind(this));

	
    }

    setEmailConfirmed() {
	this.props.props.setSignupState(false);
    }
    
    render() {
	if (this.state.signedUp) {
	    const props = {
		cognitoUser: this.cognitoUser,
		setEmailConfirmed: this.setEmailConfirmed.bind(this)
	    }
	    return(
		    <ConfirmEmail props={props}/>
	    );
	}
	else {
	return (
		<div className="SignUp">
		<form onSubmit={this.handleSubmit}>


		<FormGroup controlId="email" bssize="large">
		<FormLabel>Email</FormLabel>
		<FormControl
            autoFocus
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
		/>
		</FormGroup>

	    		<FormGroup controlId="firstname" bssize="large">
		<FormLabel>First Name</FormLabel>
		<FormControl
            autoFocus
            type="text"
            value={this.state.firstname}
            onChange={this.handleChange}
		/>
		</FormGroup>

	    		<FormGroup controlId="lastname" bssize="large">
		<FormLabel>Last Name</FormLabel>
		<FormControl
            autoFocus
            type="text"
            value={this.state.lastname}
            onChange={this.handleChange}
		/>
		</FormGroup>


		<FormGroup controlId="password" bssize="large">
		<FormLabel>Password</FormLabel>
		<FormControl
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
		/>
		</FormGroup>


	    
		<font color="red">{this.state.errorMessage}</font><br/>
		<Button
            block
            bssize="large"
            disabled={!this.validateForm()}
            type="submit"
		>
		Sign Up
            </Button>
		</form>

		</div>

	);
	}
    }
}
