import React, { Component } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import AWS from 'aws-sdk';

export default class UnClaimDevice extends Component {
    constructor(props) {
	super(props);

	this.state = {
	    deviceId: "",
	    errorMessage: ""
	}
    }

    validateForm() {
	return this.state.deviceId.length > 0;
    }

    handleChange = event => {
	console.log(event.target.value);
	this.setState({
	    deviceId: event.target.value,
	    errorMessage: ""
	});
    }

    handleSubmit = event => {
	event.preventDefault();

	this.setState({
	    errorMessage: ""
	});


	AWS.config.update({region:'us-west-2'});
	var lambda = new AWS.Lambda();
	var payload = {
	    "userid" : AWS.config.credentials.identityId,
	    "deviceid" : this.state.deviceId,
	};
	console.log(payload);
	var params = {
	    FunctionName: 'unclaimDevice',
	    Payload: JSON.stringify(payload)
	};
	lambda.invoke(params, function(err, data) {
	    if (err) {
		this.setState({
		    errorMessage: "Backend Error."
		});
	    }
	    else   {
		console.log(data);
		if (data.StatusCode===200 && !data.Payload.includes("errorMessage")) {
		    console.log("SUCCESS");
		    this.props.props.removeDevice(this.state.deviceId);
		    this.props.props.setClaimDeviceMode(false);
		}
		else {
		    this.setState({

			// b0b0 TODO be specific about error case (device does not exist; wrong code; backend error)
			errorMessage: "Unclaiming device failed."
		    });
		}
	    }
	    
	}.bind(this));

    }
    
    render() {

	var deviceoptions = [];
	for (var i=0;i<this.props.props.devices.length;i++) {
	    deviceoptions.push(<option key={this.props.props.devices[i]} value={this.props.props.devices[i]}>{this.props.props.devices[i]}</option>)
	}
	return (
	    	<div className="ClaimDevice">
		<form onSubmit={this.handleSubmit}>

		<select value={this.state.deviceid} onChange={this.handleChange}>
	    	<option value="">Select Device ID</option>
		{deviceoptions}
            </select>
		<br/>
	    
		<font color="red">{this.state.errorMessage}</font><br/>
		<Button
            block
            bssize="large"
            disabled={!this.validateForm()}
            type="submit"
		>
		unclaim
            </Button>
		</form>

		</div>

	);
    }

}
