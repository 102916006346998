import React, { Component } from "react";

export default class GamePad extends Component {

    constructor(props) {
	super(props);
	this.state =  {
	    nrcontrollers: 0
	}
    }

    componentDidMount() {
	this.controllers = {};
	this.haveEvents = 'ongamepadconnected' in window;

	window.addEventListener("gamepadconnected", this.connecthandler.bind(this));
	window.addEventListener("gamepaddisconnected", this.disconnecthandler.bind(this));

	if (!this.haveEvents) {
	    setInterval(this.scangamepads.bind(this), 500);
	}

    }
    connecthandler(e) {
	this.addgamepad(e.gamepad);
    }

    addgamepad(gamepad) {
	this.controllers[gamepad.index] = gamepad;
	requestAnimationFrame(this.updateStatus.bind(this));
    }

    disconnecthandler(e) {
	this.removegamepad(e.gamepad);
    }

    removegamepad(gamepad) {
	delete this.controllers[gamepad.index];
    }

    updateStatus() {
	if (!this.haveEvents) {
	    this.scangamepads();
	}

	this.setState({
	    nrcontrollers: Object.keys(this.controllers).length
	});
	
	var j;

	for (j in this.controllers) {
	    var controller = this.controllers[j];
	    this.props.props.setGimbalState(controller.axes[1],controller.axes[0],controller.buttons[0].pressed);
	}

	requestAnimationFrame(this.updateStatus.bind(this));
    }

    scangamepads() {
	var gamepads = navigator.getGamepads ? navigator.getGamepads() : (navigator.webkitGetGamepads ? navigator.webkitGetGamepads() : []);
	for (var i = 0; i < gamepads.length; i++) {
	    if (gamepads[i]) {
		if (gamepads[i].index in this.controllers) {
		    this.controllers[gamepads[i].index] = gamepads[i];
		} else {
		    this.addgamepad(gamepads[i]);
		}
	    }
	}
    }
    
    render() {

	const controllers = [];
	var gamepads = navigator.getGamepads ? navigator.getGamepads() : (navigator.webkitGetGamepads ? navigator.webkitGetGamepads() : []);
	for (var i=0; i<this.state.nrcontrollers;i++) {
	    var controller = <div><img height="57" width="75" src="icons/controller.png" title={gamepads[i].id}/></div>
	    controllers.push(controller);
	}
	
	if (this.state.nrcontrollers>0) {
	    return (
		    <div>{controllers}</div>
		);
	    }
	    else {
		return (
			<div>
			</div>
		);
	    }
    }

}
