import React, { Component } from "react";
import { Viewer,Entity,Cesium3DTileset } from "resium";
import { Cartesian3 } from "cesium";

import Cesium from "cesium";
Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxZmRmYTI4Yi1lYjJhLTRkMWEtYmYwYi00OTRkYTU1MDlkOGQiLCJpZCI6MTMzMTcsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1NjMwMDE0Njh9.Nrcz8Y6H4tP2IbGciDiYbl6PzIk2YV0FVrfIZNhGZ4M";

export default class Map extends Component {

    constructor(props) {
	super(props);
	this.devices = [];

    }

     handleReady = tileset => {
	if (this.viewer) {
	    this.viewer.zoomTo(tileset);
	}
    };

    render() {
	this.devices = [];
	for (var i=0; i<this.props.deviceDetails.length-1;i++) {
	    if (this.props.deviceDetails[i]!=undefined) {
		var device = this.props.deviceDetails[i];
		if (device.location.external.available) {
		    var myPosition = Cartesian3.fromDegrees(device.location.external.x, device.location.external.y, device.location.external.z);

		    var connection_state = "offline";
		    if (device.connected) {
			connection_state = "online";
		    }

		    var desc = "Type: " + device.type + "<br/>" +
			"Firmware: " + device.firmware.version + "-" + device.firmware.build + " (" + device.firmware.flavor + ")<br/><br/>" +
			"Lat: " + device.location.external.y + "<br/>" +
			"Lon: " + device.location.external.x + "<br/>" +
			"Alt: " + device.location.external.z + "<br/>" +
			"Updated: " + device.location.external.timestamp + "<br/>" +
			"<br/>" +
			"Status: " + connection_state;
		    
		    this.devices.push(
			    <Entity
			key={device.device}
			id={device.device}
			description={desc}
			name={device.name}
			point={{ pixelSize: 20 }}
			position={myPosition}
			    />
		    );
		}
	    }
	}


	//			     <Cesium3DTileset
        //url={'https://s3-us-west-2.amazonaws.com/aero.skydrone.tmp/3dtile_test04/tileset.json'}
	//onReady={this.handleReady} />

	return (
		<Viewer timeline={false} baseLayerPicker={false} fullscreenButton={false} sceneModePicker={false} selectionIndicator={false} navigationHelpButton={false} infoBox={true} homeButton={false} animation={false} geocoder="false" style={{ position: "absolute", top: 100, left: 0, right: 0, bottom: 0 }}  full
      ref={e => {
          this.viewer = e && e.cesiumElement;
      }}>
		{this.devices}
	    </Viewer>
	);
    }

}
