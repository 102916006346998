import React, { Component } from "react";

export default class WebRtcStats extends Component {

    constructor(props) {
	super(props);
	this.state =  {
	    osd_connectiontype : null,
	    osd_dataused : null,
	    osd_latency : null,
	    osd_bandwidth : null,
	    osd_framesdecoded : null,
	    osd_framesdropped : null,
	    osd_availableincomingbitrate : null
	}
	this.oldtimestamp = 0;
	this.oldbytes = 0;
	this.statsOutput = null;
	setInterval(this.processstats.bind(this),1000);
    }

    componentDidMount() {
	// fixme do setinterval in didmount and stop it in didunmount
    }


    processstats() {
	if (this.props.pc) {

	    this.props.pc.getStats(null).then(stats => {
		stats.forEach(report => {
		    //console.log("TYPE: "  + report.type);
		    if (report.type == "inbound-rtp") {
			Object.keys(report).forEach(statName => {
			    //console.log("name " + statName);
			    if (statName == "framesDecoded") {
				this.setState({osd_framesdecoded : report[statName]});
			    }
			    if (statName == "bytesReceived") {
				this.setState({osd_dataused : parseInt(report[statName]/1024/1024,10)});
			    }
			});
		    }
		    else if (report.type == "track") {
			Object.keys(report).forEach(statName => {
			    //console.log("name " + statName);
			    if (statName == "framesDropped") {
				this.setState({osd_framesdropped : report[statName]});
			    }
			});
		    }
		    else if (report.type == "candidate-pair") {
			Object.keys(report).forEach(statName => {
			    if (statName == "availableIncomingBitrate" && report["nominated"] && report["writable"]) {
				this.setState({osd_availableincomingbitrate : parseInt(report[statName]/1024,10)});
			    }
			});
		    }
		});
	    });

	    // FIXME: depricated -> remove and use promise getStats api above only
	    //console.log("processing stats");
	    this.props.pc.getStats(function(res) {
		var rtcStatsReports = res.result();
		var reportCount = rtcStatsReports.length;
		var reportIndex;
		for (reportIndex = 0; reportIndex < reportCount; reportIndex++) {
		    var report = rtcStatsReports[reportIndex];
		    if (report.type == "ssrc") {
			//console.log(report);
			var bytes = report.stat('bytesReceived');
			var timestamp = report.timestamp;
			if (this.oldtimestamp!=0) {
			    this.setState({osd_bandwidth : Math.floor((bytes-this.oldbytes)/(timestamp-this.oldtimestamp)*8)});
			}
			this.oldtimestamp = timestamp;
			this.oldbytes = bytes;
			
		    }
		    if (report.type == "googCandidatePair") {
			var line = 'type=' + report.type + '';
			var names = report.names();
			var nameIndex;
			for (nameIndex = 0; nameIndex < names.length; nameIndex++) {
			    var name = names[nameIndex];
			    var statValue = report.stat(name);
			    line += (name + ':' + statValue + ',');
			}
			if (report.stat('googActiveConnection') == "true") {
			    //console.log(line);
			    if ((report.stat('googRemoteCandidateType')=='stun' || report.stat('googRemoteCandidateType')=='local') && (report.stat('googLocalCandidateType')=='stun' || report.stat('googLocalCandidateType')=='local')) {
				this.setState({osd_connectiontype : "[P2P]"});
			    }
			    else if (report.stat('googRemoteCandidateType')=='relay' || report.stat('googLocalCandidateType')=='relay') {
				this.setState({osd_connectiontype : "[RELAY]"});
			    }
			    else {
				this.setState({osd_connectiontype : null});
			    }

			    //console.log("googLocalCandidateType: " + report.stat('googLocalCandidateType'));
			    //console.log("googRemoteCandidateType: " + report.stat('googRemoteCandidateType'));

			    //				osd_dataused : parseInt(report.stat('bytesReceived')/1024/1024,10),
			    this.setState({

				osd_latency : parseInt(report.stat('googRtt')/2,10)
			    });
			}
			
		    }
		}
		
	    }.bind(this));
	}
    }

    render() {
	return <div>
	    {this.state.osd_connectiontype}<br/>
	    Data Used: {this.state.osd_dataused} MB<br/>
	    Latency: {this.state.osd_latency} ms<br/>
	    Bandwidth: {this.state.osd_bandwidth} kbit/s<br/>
	    Frames: {this.state.osd_framesdecoded}<br/>
	    Frames Dropped: {this.state.osd_framesdropped}<br/>
	    Available Incoming Bitrate: {this.state.osd_availableincomingbitrate} kbit/s<br/>
	    {this.statsOutput}
	    </div>
	    
    }
}
