import React, { Component } from "react";
import { Rnd } from "react-rnd";
import GamePad from './GamePad.js';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Note: UI component only, functionality should be in Device class.

export default class DeviceControl extends Component {
    constructor(props) {
	super(props);
	var defaultcamerastream = 11;
	if (props.streams.list.length>0) {
	    defaultcamerastream = props.streams.list[0].id
	}
	this.state = {
	    gimbal_control_image: "icons/gimbal_control.png",
	    gimbal_control_x_axis: 0,
	    gimbal_control_y_axis: 0,
	    gimbal_web_control_x_axis: 0,
	    gimbal_web_control_y_axis: 0,
	    gimbal_global_x: 0,
	    gimbal_global_y: 0,
	    gimbal_global_x_cache: 0,
	    gimbal_global_y_cache: 0,
	    gamepad_button0_pressed: false,
	    gamepad_button0_pressed_cache: false,
	    images_captured: "-",
	    bandwidth: 0,
	    camerastream: defaultcamerastream
	};

    }

    handleCameraStreamChange = event => {
	this.setState({ [event.target.name]: event.target.value });
	console.log("Change Stream to: " + event.target.value);
	// switch plugin to stream
	this.props.functions.streamingpluginSwitch(event.target.value);
    }
    
    handleBandwidthChange = event => {
	this.setState({ [event.target.name]: event.target.value });
	if (event.target.value===-1) {
	    console.log("passthrough");
	    //TODO FIXME - implement switching to passthrough!
	}
	else if (event.target.value==0) {
	    console.log("automatic bandwidth adaption");
	    this.props.functions.controlBandwidth(100000,2000000); //FIXME - this is device speicifc
	}
	else {
	    this.props.functions.controlBandwidth(event.target.value,event.target.value);
	}
    }



    render() {
	const control_style = {
	    border: "solid 1px #ddd",
	    background: "#f0f0f0"
	};
	const gimbalMapStyle = {
	    outline: 0
	};

	var gamepad_bt0 = null;
	if (this.state.gamepad_button0_pressed) {
	    gamepad_bt0 = <div>trigger</div>;
	}
	else {
	    gamepad_bt0 = <div></div>;
	}


	var list = this.props.streams.list
	let camerastreams = []
	for (var i=0;i<list.length;i++) {
	    var id = list[i].id;
	    var name = list[i].description;
	    var age = list[i].video_age_ms;
	    camerastreams.push(<MenuItem value={id} key={id}>{name}</MenuItem>)
	}
	

	// Yuneec Firmware
	if (this.props.data.firmware.flavor==='yuneec') {
	    	    return 	(
		    <Rnd
		style={control_style}
		default={{
		    x: 1200,
		    y: 400,
		    width: 190,
		    height: 500
		}}

		    >
		    <h1>Device Control</h1>
		    <h2>{this.props.data.name}</h2>
		    
		    <Select
		value={this.state.bandwidth}
		onChange={this.handleBandwidthChange}
		inputProps={{
		    name: 'bandwidth',
		}}
		    >
		    <MenuItem value={0}>automatic</MenuItem>
		    <MenuItem value={1000000}>1Mbit/s</MenuItem>
		    <MenuItem value={500000}>500kbit/s</MenuItem>
		    <MenuItem value={100000}>100kbit/s</MenuItem>
		    </Select>

		    <br/>

		    <Select
		value={this.state.camerastream}
		onChange={this.handleCameraStreamChange}
		inputProps={{
		    name: 'camerastream',
		}}
			    >
			    {camerastreams}
		    </Select>

		
		    <map name="primary">
		    <area style={gimbalMapStyle} onClick={(e) => {e.preventDefault()}} onMouseDown={(e) => {this.props.functions.cmd_gimbal_control2('left',1,e)}} onMouseUp={(e) => {this.props.functions.cmd_gimbal_control2('left',0,e)}} alt="left" title="left" href="" coords="43,41,43,109,2,76" shape="poly"/>
		    <area style={gimbalMapStyle} onClick={(e) => {e.preventDefault()}} onMouseDown={(e) => {this.props.functions.cmd_gimbal_control2('up',1,e)}} onMouseUp={(e) => {this.props.functions.cmd_gimbal_control2('up',0,e)}} alt="up" title="up" href="" coords="74,3,105,44,46,42" shape="poly"/>
		    <area style={gimbalMapStyle} onClick={(e) => {e.preventDefault()}} onMouseDown={(e) => {this.props.functions.cmd_gimbal_control2('right',1,e)}} onMouseUp={(e) => {this.props.functions.cmd_gimbal_control2('right',0,e)}} alt="right" title="right" href="" coords="107,39,106,108,149,76" shape="poly"/>
		    <area style={gimbalMapStyle} onClick={(e) => {e.preventDefault()}} onMouseDown={(e) => {this.props.functions.cmd_gimbal_control2('down',1,e)}} onMouseUp={(e) => {this.props.functions.cmd_gimbal_control2('down',0,e)}} alt="down" title="down" href="" coords="46,108,104,108,75,147" shape="poly"/>
		    </map>
		    <img useMap="#primary" src={this.state.gimbal_control_image}/>
		    <img onClick={(e) => {this.props.functions.reset_gimbal()}} src="icons/gimbal_reset.png"/>
		    <button onClick={(e) => {this.props.functions.trigger_camera()}}>trigger</button>

		{gamepad_bt0}
		
		
		
		    <GamePad/>
		    <br/>
		    <br/>
		    <small>Firmware: {this.props.data.firmware.version}-{this.props.data.firmware.build} ({this.props.data.firmware.flavor})</small>

		</Rnd>
	    );
	}
	// everything else (Simulator / FPV / Link)
	else {
	    var extendedChoice = <Select
		value={this.state.bandwidth}
		onChange={this.handleBandwidthChange}
		inputProps={{
		    name: 'bandwidth',
		}}
		    >
		    <MenuItem value={0}>automatic</MenuItem>
		    <MenuItem value={2000000}>2Mbit/s</MenuItem>
		    <MenuItem value={1000000}>1Mbit/s</MenuItem>
		    <MenuItem value={500000}>500kbit/s</MenuItem>
		    <MenuItem value={100000}>100kbit/s</MenuItem>
		</Select>;

	    if (this.props.data.firmware.flavor==='link3') {
		extendedChoice = <Select
		value={this.state.bandwidth}
		onChange={this.handleBandwidthChange}
		inputProps={{
		    name: 'bandwidth',
		}}
		    >
		    <MenuItem value={0}>automatic</MenuItem>
		    <MenuItem value={20000000}>20Mbit/s</MenuItem>
		    <MenuItem value={10000000}>10Mbit/s</MenuItem>
		    <MenuItem value={5000000}>5Mbit/s</MenuItem>
		    <MenuItem value={2000000}>2Mbit/s</MenuItem>
		    <MenuItem value={1000000}>1Mbit/s</MenuItem>
		    <MenuItem value={500000}>500kbit/s</MenuItem>
		    <MenuItem value={100000}>100kbit/s</MenuItem>
		</Select>;
	    }


	    
	    return 	(
		    <Rnd
		style={control_style}
		default={{
		    x: 1200,
		    y: 400,
		    width: 190,
		    height: 250
		}}
		    >

		
		    <h1>Device Control</h1>
		    <h2>{this.props.data.name}</h2>
		    {extendedChoice}		    
		    <Select
		value={this.state.camerastream}
		onChange={this.handleCameraStreamChange}
		inputProps={{
		    name: 'camerastream',
		}}
			    >
			    {camerastreams}
		    </Select>

		    <br/>
		    <br/>
		    <small>Firmware: {this.props.data.firmware.version}-{this.props.data.firmware.build} ({this.props.data.firmware.flavor})</small>

		</Rnd>
	    );
	}
    }
}

//		    <MenuItem value={10}>MP:10</MenuItem>


//		    <button onClick={(e) => {this.props.functions.cmd_rtl()}}>RTL</button>
//		    <button onClick={(e) => {this.props.functions.cmd_startmission()}}>Start Mission</button>
//		    <button onClick={(e) => {this.props.functions.cmd_hold()}}>pause mission</button>


