/*global Paho*/
import React, { Component } from "react";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


export default class DeviceConfig extends Component {

    constructor(props) {
	super(props);
	this.state =  {
	    inConfig : true,
	    broadcast : "disabled",
	    unleashlive_url : null,
	    youtube_url : null,
	    facebook_url: null
	}
	this.saving = false;
	this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleBroadcastChange = event => {
	this.setState({ [event.target.name]: event.target.value });
    }

    handleUnleashliveUrlChange = event => {
	this.setState(
	    {
		unleashlive_url: event.target.value
	    }
	);
    }

    handleYoutubeUrlChange = event => {
	this.setState(
	    {
		youtube_url: event.target.value
	    }
	);
    }

    handleFacebookUrlChange = event => {
	this.setState(
	    {
		facebook_url: event.target.value
	    }
	);
    }

    handleSubmit(event) {
	event.preventDefault();
	this.saving = true;

	var msg = {
	    "config": {
		"unleashlive_url" : this.state.unleashlive_url,
		"youtube_url" : this.state.youtube_url,
		"facebook_url" : this.state.facebook_url
	    }
	}
	if (this.props.mqttclient) {
	    var message = new Paho.MQTT.Message(JSON.stringify(msg));
	    message.destinationName = "cc/" + this.props.device + "/config/fromclient/";
	    console.log(message.destinationName);
	    this.props.mqttclient.send(message);
	}
	else {
	    console.log("MQTT connection not ready!");
	    // TODO notify user saving FAILED
	}
	// TODO -> wait form confirmation?
	this.saving = false;
    }	

    
    render() {
	var labelStyle = {
	    'display': 'inline-block',
	    'text-align': 'left',
	    'width': '250px'
	}

	var detailedConfig = <div></div>;
	if (this.state.broadcast=="unleashlive") {
	    detailedConfig = <div>
		<label style={labelStyle}>
		Stream URL:
		</label>
		<input type="text" value={this.state.unleashlive_url} onChange={this.handleUnleashliveUrlChange} />
		<br/>
		</div>;

	}
	else if (this.state.broadcast=="youtube") {
	    detailedConfig = <div>
		<label style={labelStyle}>
		Stream URL + Stream Key:
		</label>
		<input type="text" value={this.state.youtube_url} onChange={this.handleYoutubeUrlChange} />
		<br/>
		</div>;

	}
	else if (this.state.broadcast=="facebook") {
	    detailedConfig = <div>
		<label style={labelStyle}>
		Stream URL:
		</label>
		<input type="text" value={this.state.facebook_url} onChange={this.handleFacebookUrlChange} />
		<br/>
		</div>;

	}

	
	let updatebutton;
	updatebutton = 		<input disabled={this.saving} type="submit" value="Save" />

	if (this.state.inConfig) {
	    return <div>
		Broadcast Stream:<br/>
	    	<Select
		value={this.state.broadcast}
		onChange={this.handleBroadcastChange}
		inputProps={{
		    name: 'broadcast',
		}}
		    >
		<MenuItem value="disabled">disabled</MenuItem>
		    <MenuItem value="youtube">Youtube</MenuItem>
		    <MenuItem value="facebook">Facebook</MenuItem>
		    <MenuItem value="unleashlive">Unleash Live</MenuItem>
		    </Select>
		{detailedConfig}
	    	<form onSubmit={this.handleSubmit}>
		{updatebutton}
	    </form>
	    </div>
	}
	else {
	    return <div>
		Configuration hidden
	    </div>
	}
    }
	
    
}
