import React, { Component } from "react";
import AWS from 'aws-sdk';
import {
    CognitoUserPool
} from 'amazon-cognito-identity-js';

export default class Account extends Component {

    signout() {
	// b0b0 DRY -> put this in a global config object
	AWS.config.region = 'us-east-1';
	this.poolData = {
  	    UserPoolId : 'us-east-1_7dojC06dm',
	    ClientId :   '10gmq555pdji7gsoc9qpl8lajn'
	};
	this.userPool = new CognitoUserPool(this.poolData);

	var cognitoUser = this.userPool.getCurrentUser();
	if (cognitoUser != null) {
            cognitoUser.signOut();
	    console.log("signed out.");
	    //b0b0: fixme - also invaliate AWS token; stop connections etc?
	    this.props.props.userHasAuthenticated(false);	    
	}

    }
    
    render() {
	const style = {
	    "float": "right",
//	    "padding-top": "5px",
//	    "padding-right": "20px",
//	    "padding-bottom": "0px",
//	    "padding-left": "0px",
	};

	return (
		<div style={style}>
		<img alt="logout" title="logout" width="32px" src="icons/logout.png" onClick={() => {this.signout()}}/>
		</div>
	);
    }
}
